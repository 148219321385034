module.exports = function(parent, tag, className, html) {
    var el = window.document.createElement(tag);

    if (className) {
        el.classList.add(className);
    }

    if (html) {
        el.innerHTML = html;
    }

    return parent.appendChild(el);
};
