var errorLogger = require('./logging'),
    storage = require('./storage');

module.exports = function enableEventBuffering(domNode, eventTypes) {
    var bufferHash = {},
        bufferedCounts = {},
        triggeredCounts = {},
        dispatchedCounts = {};

    eventTypes.forEach(function(eventType) {
        bufferHash[eventType] = [];

        // set up counts for each event (for error logging)
        bufferedCounts[eventType] = 0;
        triggeredCounts[eventType] = 0;
        dispatchedCounts[eventType] = 0;

        // ensure buffered and triggered counts always appear (even if the count is 0).
        errorLogger.setLoggedParam(eventType + '-BufferedCount', bufferedCounts[eventType]);
        errorLogger.setLoggedParam(eventType + '-LateSubscribersCount', triggeredCounts[eventType]);
        errorLogger.setLoggedParam(eventType + '-DispatchedCount', dispatchedCounts[eventType]);

        domNode.addEventListener(eventType, function(event) {

            // If we encounter an error,
            // we want to know how many times the events were buffered.
            bufferedCounts[eventType] = bufferedCounts[eventType] + 1;
            errorLogger.setLoggedParam(eventType + '-BufferedCount', bufferedCounts[eventType]);

            bufferHash[eventType].push(event);
        });
    });

    var originalDispatchEvent = domNode.dispatchEvent,
        originalAddEventListener = domNode.addEventListener;

    // Technically, hijacking dispatchEvent is not part of buffering,
    // but we want to inject logging and it's convenient to do it here.
    domNode.dispatchEvent = function(event) {
        var type = event.type;

        if (eventTypes.indexOf(type) > -1) {
            // We want to know how many times the events are dispatched
            dispatchedCounts[type] = dispatchedCounts[type] + 1;
            errorLogger.setLoggedParam(type + '-DispatchedCount', dispatchedCounts[type]);

            // If this gets invoked a second time, log once per browser session
            if (dispatchedCounts[type] > 1) {
                if (!storage.session.getItem('bridgeBar.' + type + 'DispatchLogged')) {
                    storage.session.setItem('bridgeBar.' + type + 'DispatchLogged', true);
                    errorLogger.warn(new Error(type + ' event dispatched more than once'));
                }
            }
        }

        return originalDispatchEvent.apply(domNode, arguments);
    };

    domNode.addEventListener = function(type, listener) {
        if (eventTypes.indexOf(type) > -1) {
            bufferHash[type].forEach(function(event) {

                // If we encounter an error,
                // we want to know how many times the buffered events were triggered.
                triggeredCounts[type] = triggeredCounts[type] + 1;
                errorLogger.setLoggedParam(type + '-LateSubscribersCount', triggeredCounts[type]);

                listener(event);
            });
        }

        originalAddEventListener.apply(domNode, arguments);
    };
};
