var events = require('./events');

events.stateDetermined.publish(false, new Error('unsupported browser'));

var bb = {
    render: na,
    updateDealerLabelHtml: na,
    updateUserData: na,
    collapseComponents: na,
    onStateDetermined: onStateDetermined,
    onComponentExpanded: na
};

module.exports = bb;

function onStateDetermined(callback) {
    events.stateDetermined.subscribe(callback);
    return bb;
}

// no-op function when not applicable
function na() {
    return bb;
}
