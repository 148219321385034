/* eslint-disable camelcase */
var rickRollUrl = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ';

module.exports = {
    bridgeApi: {
        username: 'rickroll2007',
        given_name: 'Rick',
        family_name: 'Astley',
        solution_links: [
            {
                solutionId: 'Admin_DEV',
                label: 'Admin',
                uri: rickRollUrl,
                mode: 'NEW_WINDOW',
            },
            {
                solutionId: 'DDC_DEV',
                label: 'Dealer.com',
                uri: rickRollUrl,
                mode: 'NEW_WINDOW',
            },
            {
                solutionId: 'TESTSOLUTION_ENV1',
                label: 'Test Solution (Env1)',
                uri: rickRollUrl,
            },
            {
                solutionId: 'TESTSOLUTION_ENV2',
                label: 'Test Solution (Env2)',
                uri: rickRollUrl,
            },
            {
                solutionId: 'DTCOM_DEV',
                label: 'Dealertrack F&I',
                uri: rickRollUrl,
                mode: 'NEW_WINDOW',
            },
            {
                solutionId: 'RTS_DEV',
                label: 'Reg & Titling',
                uri: rickRollUrl,
                mode: 'NEW_WINDOW',
            },
        ],
        display_info: [
            {
                header: 'Bridge ID',
                body: 'rickroll2007',
            },
            {
                header: 'Name',
                body: 'RICK ASTLEY',
            },
        ],
        user_links: [
            {
                label: 'My Settings',
                uri: rickRollUrl,
                icon: 'my-settings',
                menu: 'user',
                mode: 'NEW_WINDOW',
            },
            {
                label: 'Bridge User Admin',
                uri: rickRollUrl,
                icon: 'bridge-admin',
                menu: 'user',
                mode: 'NEW_WINDOW',
            },
            {
                label: 'Help',
                uri: rickRollUrl,
                icon: 'help',
                menu: 'user',
                mode: 'NEW_WINDOW',
            },
            {
                label: 'Attach More Solutions',
                uri: rickRollUrl,
                icon: 'attach-more-solutions',
                menu: 'solution',
                mode: 'NEW_WINDOW',
            },
        ],
        feature_flags: {
            'pdt.example-FF': {
                value: true,
            },
        },
    },
};
