var createElement = require('./createElement');

module.exports = function() {
    var dcWrapper = createElement('div', 'bridge-bar-dealer-context');

    return {
        attach: function(bbEl) {
            dcWrapper.style.display = 'none';
            bbEl.appendChild(dcWrapper);
        },
        setDealerLabel: function(label) {
            if (dcWrapper.style.display === 'none') {
                dcWrapper.style.display = '';
            }
            dcWrapper.innerHTML = label;
        }
    };
};
