var loggedParams = {};

module.exports = {
    setLoggedParam: function(key, obj) {
        // Store a copy of the object in loggedParams
        loggedParams[key] = JSON.parse(JSON.stringify(obj));
    },

    error: function() {
        return;
    },

    warn: function() {
        return;
    },

    info: function() {
        return;
    },

    debug: function() {
        return;
    },

    stats: function() {
        return;
    }
};

// for unit testing (wrapper needed for consistent stack frame count)
module.exports._testBuildError = function(err) {
    return buildError(err);
};

function buildError(err) {

    // err can be:
    // - Error (with optional .meta)
    // - null
    // - other object
    // - string / number / boolean
    var error;

    if (err instanceof Error) {
        // Error objects cannot be stringified, so make a copy
        error = {
            message: err.message,
            stack: err.stack
        };

        /* eslint guard-for-in:0*/
        for (var attr in err.meta) {
            error[attr] = err.meta[attr];
        }

    } else {
        error = makeObject(err);
    }

    if (!error.stack) {
        // create stack, dropping frames originating from this module
        error.stack = makeStack(2);
    }

    if (error.stack && error.stack.length > 500) {
        error.stack = error.stack.substring(0, 500);
    }

    // Ensure every error identifies itself as "bridge".
    error.component = 'bridge';

    return error;
}

function makeObject(err) {
    if (err === null) {
        return {};
    } else if (typeof err === 'object') {
        return err;
    } else {
        return { message: err.toString() };
    }
}

function makeStack(dropFrames) {
    var stack,
        frames;

    // some browsers don't attach stack until error is thrown
    try {
        throw new Error();
    } catch (e) {
        stack = e.stack;
    }

    if (stack) {
        frames = stack.split('\n');
        frames.splice(1, dropFrames + 2);
        stack = frames.join('\n');
    }

    return stack;
}
