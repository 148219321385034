var analyticsEmitter = { emit: new Function() },
    popoverHandler = require('./popoverHandler')(analyticsEmitter),
    solutionSwitcher = require('./solutionSwitcher')(popoverHandler, analyticsEmitter),
    dealerContext = require('./dealerContext')(),
    userMenu = require('./userMenu')(popoverHandler, analyticsEmitter),
    userMenuBuilt = false,
    logo = require('./logo')(analyticsEmitter),
    componentWrapper = require('./componentWrapper')(),
    bbEl;

var THEMES = require('./themes'),
    bridgeBarStates = require('./bridgeBarStates');

var bbc = {
    setSolutionSwitcher: function(solutionSwitcherObject) {
        if (!solutionSwitcherObject) {
            return bbc;
        }
        if (!solutionSwitcherObject.labelHtml || !Array.isArray(solutionSwitcherObject.solutionGroups)) {
            throw new Error('an object with as least `labelHtml` and `solutionGroups` is required');
        }
        solutionSwitcher.build(solutionSwitcherObject);
        return bbc;
    },

    setUserMenu: function(userMenuObject) {
        if (!userMenuObject || (userMenuObject.bridgeBarState === bridgeBarStates.RENDER && !userMenuObject.userLabelHtml)) {
            throw new Error('an object with at least `userLabelHtml` is required');
        }

        userMenu.build(userMenuObject);
        userMenuBuilt = true;
        return bbc;
    },

    setDealerLabelHtml: function(dealerLabelHtml) {
        if (!userMenuBuilt) {
            throw new Error('must invoke `setUserMenu` before `setDealerLabelHtml`');
        }
        dealerContext.setDealerLabel(dealerLabelHtml);
        return bbc;
    },

    setAnalyticsEmitter: function(emitter) {
        // we've already injected the analyticsEmitter, so don't change its pointer
        analyticsEmitter.emit = emitter.emit.bind(emitter);
        return bbc;
    },

    updateUserData: function(userDataObject) {
        if (!userMenuBuilt) {
            throw new Error('must invoke `setUserMenu` before `updateUserData`');
        }

        userMenu.updateUserData(userDataObject);
        return bbc;
    },

    setWebComponents: function(components) {
        components.forEach(function(component) {
            componentWrapper.addComponent(component);
        });
    },

    setLandingPageUrl: function(url) {
        logo.setLandingPageUrl(url);
    },

    show: function(theme, width) {
        if (!bbEl) {
            attach('show');
        }
        logo.show();
        require('../../dist/bridge.css');

        if (width) {
            // If we use 50%,
            // IE11 will repaint when browser width changes. :P
            bbEl.style.padding = '0 calc(49.9% - (' + width + ') / 2)';
        }
        bbEl.classList.remove('bb-hidden');

        var applyTheme;

        if (!theme) {
            applyTheme = THEMES[0].css;
        }
        else {
            var found = THEMES.filter(function(th) {
                return th.name === theme;
            });

            if (found && found.length > 0) {
                applyTheme = found[0].css;
            } else {
                applyTheme = THEMES[0].css;
                console.warn('Unknown theme: ' + theme + '; applying default'); // eslint-disable-line
            }
        }

        require('../../dist/' + applyTheme + '.css');

        return bbc;
    },

    collapseComponents: function() {
        popoverHandler.collapseAll();
        return bbc;
    },

    componentExpandedStream: {
        subscribe: function(callback) {
            popoverHandler.onExpanded(callback);
        }
    }
};

function attach(method) {
    bbEl = window.document.querySelector('bridge-bar');
    if (!bbEl) {
        throw new Error(method + ' called, but <bridge-bar> tag was not found in markup.');
    }

    logo.attach(bbEl);
    solutionSwitcher.attach(bbEl);
    componentWrapper.attach(bbEl);
    dealerContext.attach(bbEl);
    userMenu.attach(bbEl);
}

module.exports = bbc;
