module.exports = {

    /**
     * Determine the left/right position of the popover component. Specifically,
     * If popover fits within window.innerWidth when center-aligned below the
     * center of the triggering element, do that. Else, ensure the right edge of the
     * popover is at least 16px from the Bridge Bar's right edge, and the left edge
     * of the popover is at least 0px from the window's left edge.
     *
     * @param  {string} trigger - popover-triggering element
     * @return {undefined}
     */
    positionPopover: function(trigger) {
        var triggerLeft = Math.ceil(trigger.getBoundingClientRect().left + window.document.body.scrollLeft),
            triggerWidth = trigger.getBoundingClientRect().width,
            triggerCenter = triggerLeft + (triggerWidth / 2),
            popover = trigger.parentElement.querySelector('.bb-popover'),
            bridgeBarRight,
            popoverWidth,
            fitsOnRight,
            fitsOnLeft,

            // Capture the inner width now, in specific circumstances (~375 px width smaller than chrome's min width) the
            // "left auto" setting below can cause premature render and change this innerWidth calculation causing the menu.
            // to be rendered partially off-screen and requiring the user to scroll to use the screen.
            innerWidth = window.innerWidth;

        // Reset popover.style.left and popover.style.right
        // to determine accurate popoverWidth,
        // e.g., in case window resized.
        // (Additionally, this is a safe place,
        // i.e., unlikely to create a horizontal scrollbar,
        // to stow the popover when it is hidden.)
        popover.style.left = '0';
        popover.style.right = 'auto';

        // If (and only if!) the trigger is *about to be selected,*
        // position the (visible) popover.
        if (!trigger.classList.contains('selected')) {
            popoverWidth = popover.getBoundingClientRect().width;
            fitsOnRight = (triggerCenter + (popoverWidth / 2)) <= innerWidth;
            fitsOnLeft = (triggerCenter - (popoverWidth / 2)) >= 0;

            bridgeBarRight = window.document.querySelector('bridge-bar').getBoundingClientRect().right;

            // set left to 'auto' in case we only set right below.
            popover.style.left = 'auto';

            if (fitsOnRight && fitsOnLeft) {
                popover.style.right = (bridgeBarRight - triggerCenter - (popoverWidth / 2)) + 'px';
            } else {
                if (!fitsOnRight) {
                    popover.style.right = (bridgeBarRight - Math.max(innerWidth - 16, triggerCenter + 16)) + 'px';
                }
                if (!fitsOnLeft) {
                    popover.style.left = '12px';
                }
            }
        }
    }
};
