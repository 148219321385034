var THEMES = Object.freeze([
    {
        name: 'Bianco',
        css: 'theme-interstate-light'
    },
    {
        name: 'Titanium-300',
        css: 'theme-interstate-light'
    },
    {
        name: 'Titanium-400',
        css: 'theme-interstate-light'
    },
    {
        name: 'Cobalt-600',
        css: 'theme-interstate-dark'
    },
    {
        name: 'Cobalt-700',
        css: 'theme-interstate-dark'
    },
    {
        name: 'Cobalt-800',
        css: 'theme-interstate-dark'
    },
    {
        name: 'Interstate-Light',
        css: 'theme-interstate-light'
    },
    {
        name: 'Interstate-Dark',
        css: 'theme-interstate-dark'
    }

]);

module.exports = THEMES;
