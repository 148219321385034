var LANGUAGES = Object.freeze([
    {
        name: 'en',
        file: 'en/translation'
    },
    {
        name: 'es',
        file: 'es/translation'
    }, {
        name: 'fr',
        file: 'fr/translation'
    },
]);

module.exports = function(language) {
    var filteredLang = LANGUAGES.filter(function(lang) {
        return lang.name === language;
    });

    // if we want to alert on bad language
    if (language && filteredLang.length === 0) {
        console.warn('Unknown language: ' + language + '; using default language'); // eslint-disable-line
    }

    var selectedLanguage = filteredLang.length !== 0 ? filteredLang[0] : LANGUAGES[0];

    return require('./locales/' + selectedLanguage.file + '.json');
};
