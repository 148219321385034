module.exports = {
    determineAction: determineAction
};

function determineAction(eventName, eventData) {
    if (eventName === 'contextmenu') {
        return 'Right Click';
    }
    if (eventName === 'click') {
        return isModifierKey(eventData) ? 'Modified Left Click' : 'Left Click';
    }
    return null;
}

function isModifierKey(e) {
    return e && (e.shiftKey || e.ctrlKey || e.altKey || e.metaKey);
}
