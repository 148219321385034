module.exports = {
    local: safeJsonStore(window.localStorage),
    session: safeJsonStore(window.sessionStorage)
};

function safeJsonStore(storage) {
    return {

        getItem: function(key) {
            try {
                var value = storage.getItem(key);

                // eslint-disable-next-line no-undefined
                return value === 'undefined' ? undefined : window.JSON.parse(value);
            } catch (ignore) {
                // in case unsupported
                return null;
            }
        },

        setItem: function(key, value) {
            try {
                storage.setItem(key, window.JSON.stringify(value));
            } catch (ignore) {
                // in case unsupported or "quota exceeded"
            }
        }
    };
}
