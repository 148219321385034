module.exports = {
    add: add,
    trigger: trigger
};

var triggered = false,
    actions = [];

function add(action) {
    if (triggered) {
        action();
    } else {
        actions.push(action);
    }
}

function trigger() {
    triggered = true;
    actions.forEach(function(action) {
        action();
    });
}
