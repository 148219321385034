var getTracker = function() { return window.dataLayer; },
    emitHistory = {};

module.exports = {
    setTrackerProvider: function(provider) {
        if (provider) {
            getTracker = provider;
        }
    },

    emit: function(data) {
        var tracker = getTracker ? getTracker() : null;
        var suppressEmitMilliseconds = 20;

        var baselineEpoch = new Date().getTime();

        if (!tracker) {
            return;
        }

        // Certain solution groups seem to rapidly trigger duplicate events to
        // occur within the browser.  This block attempts to limit how many
        // times a specific type of message can be emited to Google Analytics.
        // One theory is this is related to New Relic overloading the browser
        // eventing mechanism.  [1/2018 SB]

        if (data.event && data.eventResult) {
            var keyName = JSON.stringify(data);

            if (emitHistory[keyName]) {

                var lastEmitEpoch = emitHistory[keyName];
                var intervalSinceLastEmitMilliseconds = baselineEpoch - lastEmitEpoch;

                if (intervalSinceLastEmitMilliseconds < suppressEmitMilliseconds) {
                    return;
                }
            }

            emitHistory[keyName] = baselineEpoch;
        }

        tracker.push('send', 'event', data);
    }
};
