/*
 DT standard (high) security has a session time out of 60 minutes. Keep-alive interval is 15 minutes because we
 believe 25% of the session time out adds minimal server load and is frequent enough to ensure an active user's
 session does not time out.

 With our implementation, it is possible that a user may perceive their session times out after just 45 minutes of
 inactivity (when their last interaction happens just within the 15-minute window of our previous keep-alive
 request), but we believe 75% of the 60-minute session is acceptable to consider a user as inactive.

 We delay the initial keep-alive request by 2 minutes to accommodate RTS java applet initialization, but we don't
 delay longer than this, to avoid a scenario where a user times out after an hour because they never stayed long
 enough on any single page for the keep-alive to fire.
*/
var intervalMilliseconds = (15 * 60 * 1000),
    initialDelayMilliseconds = (2 * 60 * 1000);

module.exports = function keepAlive(pixelUrl) {
    if (!pixelUrl) {
        return;
    }

    ['keydown', 'mousedown'].forEach(function(event) {
        window.document.body.addEventListener(event, throttle(function() {
            refreshSession(pixelUrl);
        }, intervalMilliseconds, initialDelayMilliseconds));
    });
};

// for unit testing
module.exports._throttle = throttle;

function throttle(callback, interval, initialDelay) {
    // initialize such that we wait for initialDelay before firing
    var lastCall = new Date().getTime() - interval + initialDelay;

    return function emit() {
        var now = new Date().getTime();

        if (now - lastCall > interval) {
            lastCall = now;
            callback.call(arguments);
        }
    };
}

function refreshSession(pixelUrl) {
    var img = window.document.createElement('img');

    // TODO: We should remove the uid cachebust once we move to the new api and let cache headers manage this
    img.src = pixelUrl + (pixelUrl.indexOf('?') === -1 ? '?uid=' : '&uid=') + new Date().getTime().toString();
    img.style.display = 'none';
    img.onload = img.onerror = function() {
        try {
            window.document.body.removeChild(img);
        } catch (ignore) {
            // do nothing
        }
    };

    window.document.body.appendChild(img);
}
