var positioner = require('./popoverPositioner'),
    addChildElement = require('./addChildElement'),
    eventHelper = require('./eventHelper'),
    analyticsEmitter,
    popovers = [],
    expandCallbacks = [],
    initialized = false;

module.exports = function(ae) {
    analyticsEmitter = ae;

    return {
        attach: attach,
        collapseAll: collapseAll,
        onExpanded: onExpanded
    };
};

function attach(key, label, el) {
    if (!initialized) {
        window.document.body.addEventListener('click', function() { collapseAll(); });
        window.addEventListener('hashchange', function() { collapseAll(); });
        initialized = true;
    }

    popovers.push(el);

    el.addEventListener('click', function(e) {
        e.stopPropagation();

        positioner.positionPopover(el);
        var wasOpen = toggle(key, el);

        analyticsEmitter.emit({
            event: 'userEvent',
            eventFeature: 'bridge bar',
            eventFeatures: 'bridge bar > ' + label,
            eventElement: label,
            eventAction: eventHelper.determineAction('click', e),
            eventResult: 'popover ' + (wasOpen ? 'collapsed' : 'expanded')
        });
    });
}

function collapseAll() {
    popovers.forEach(function(el) {
        if (el) {
            el.classList.remove('selected');
        }
    });
    setOverlay(false);
}

function onExpanded(callback) {
    expandCallbacks.push(callback);
}

function toggle(key, el) {
    var wasOpen = (el.classList.contains('selected'));

    collapseAll();

    if (!wasOpen) {
        setOverlay(true);
        el.classList.add('selected');
        expandCallbacks.forEach(function(callback) { callback([key]); });
    }

    return wasOpen;
}

function setOverlay(visible) {
    var o = window.document.querySelector('.bridge-bar-menu-overlay');

    if (!o) {
        o = addChildElement(window.document.body, 'div', 'bridge-bar-menu-overlay');
        o.style.display = 'none';
        o.addEventListener('click', function() {
            collapseAll();
        });
    }

    o.style.display = visible ? 'block' : 'none';
}
