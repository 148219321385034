var bbEl = require('./src/bridgeBarElement'),
    bbc = require('./src/component/bridgeBarComponent'),
    enableEventBuffering = require('./src/enableEventBuffering'),
    browserSupports = require('./src/browserSupports');

if (browserSupports.customEvents) {
    // start buffering - this must happen before we dispatch any events
    enableEventBuffering(bbEl, ['initialized', 'bridgeApiResponded']);
}

// expose the adapter api - this may dispatch "browser unsupported" event
bbEl.bb = browserSupports.bridgeBar ? require('./src/api') : require('./src/apiUnsupported');

// conditionally expose component api (need to support IE7 here)
if ((' ' + bbEl.className + ' ').indexOf(' bbc ') > -1) {
    bbEl.bbc = bbc;
}

// invoke global init callback if present (for older browser support)
if (window.bridgeBarInitialized) {
    window.bridgeBarInitialized(bbEl.bb);
}

// dispatch init event (preferred if older browser support not needed)
if (browserSupports.customEvents) {
    bbEl.dispatchEvent(new CustomEvent('initialized', {
        detail: { bb: bbEl.bb }
    }));
}
