
/**
 * This module should check interesting timings as they come in (submitTime ( key )) would save into
 * an array.
 */

var version = require('../version'),
    stats = require('./logging').stats;

var baselineEpoch = new Date().getTime(),
    performanceUrl = [],
    telemetryArtifact = { apiStats: {},
        config: {},
        performance: [],
        summaryMetric: {
            state: null,
            totalRenderTime: null,
            retryAPI: 0,
            shownMinimal: null,
            shownLoading: false
        },
        timings: [],
        apiVersion: null,
        version: version
        };

module.exports = {
    checkpoint: function(key) {
        var deltaFromBaselineEpoch = new Date().getTime() - baselineEpoch;
        var timingEntry = {};

        timingEntry.event = key;
        timingEntry.elapsed = deltaFromBaselineEpoch;
        telemetryArtifact.timings.push(timingEntry);
    },
    loadingShown: function() {
        this.checkpoint('loading-paint');
        this.setSummaryMetric('shownLoading', true);
    },
    finalPaint: function(state) {
        this.checkpoint('final-paint');
        if (state) {
            this.setState(state);
        } else {
            this.setState('shown');
        }
    },
    registerUrls: function(url) {
        /**
         * Log URL's that we will attempt to interogate from the Browser performance API during transmit.
         */
        performanceUrl = Object.keys(url).map(function(key) {
            return url[key];
        });
    },
    setApiAttempt: function(apiName, outcome, responseTime) {
        if (!telemetryArtifact.apiStats[apiName]) {
            telemetryArtifact.apiStats[apiName] = [];
        }

        // remove deprecated "code" attribute if dashboards are not using it
        telemetryArtifact.apiStats[apiName].push({ responseTime: responseTime, outcome: outcome, code: Number(outcome) });
    },
    setApiVersion: function(apiVersion) {
        telemetryArtifact.apiVersion = apiVersion;
    },
    setSummaryMetric: function(key, value) {
        telemetryArtifact.summaryMetric[key] = value;
    },
    setState: function(state) {
        telemetryArtifact.summaryMetric.state = state;
    },
    storeArtifact: function(key, artifact) {
        telemetryArtifact[key] = artifact;
    },
    storeBrowserMetadata: function() {
        this.storeArtifact('href', window.location.href);
        this.storeArtifact('ua', navigator.userAgent);
    },
    transmit: function() {
        telemetryArtifact.summaryMetric.totalRenderTime = new Date().getTime() - baselineEpoch;

        // The browser performance API seems to be asynchronous and require a delay before we read from it.
        setTimeout(function() {
            var perfEntries = window.performance.getEntries().filter(function(perfEntry) {
                for (var i = 0; i < performanceUrl.length; i++) {
                    if (perfEntry.name.indexOf(performanceUrl[i]) !== -1) {
                        return true;
                    }
                }
                return false;
            });

            if (perfEntries.length) {
                telemetryArtifact.performance = perfEntries;
            }
            stats(telemetryArtifact);
        }, 1000);
    }
};
