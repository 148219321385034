// This module is central to wiring everything up, so it gets hit by all browsers. Thus we
// need to support least common denominator (IE7) and therefore avoid querySelector() etc.
module.exports = (function() {
    // preferred approach is for adopter to include <bridge-bar> in dom
    var tags = window.document.getElementsByTagName('bridge-bar'),
        bbEl = tags ? tags[0] : null;

    // alternatively, they may provide element with id="bridge-bar", which we use as a wrapper
    if (!bbEl) {
        bbEl = window.document.getElementById('bridge-bar');
        if (bbEl) {
            bbEl.appendChild(window.document.createElement('bridge-bar'));
        }
    }

    if (!bbEl) {
        throw new Error('Could not find <bridge-bar> or element with id="bridge-bar". Must be placed on page before bridge-bar.js script.');
    }

    return bbEl;
}());
