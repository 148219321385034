var bbc = require('./component/bridgeBarComponent'),
    bridgeApiClient = require('./bridgeApiClient'),
    bridgeApiAdapter = require('./bridgeApiAdapter'),
    bridgeApiUrlBuilder = require('./bridgeApiUrlBuilder'),
    keepAlive = require('./keepAlive'),
    updateActions = require('./delayedActions'),
    applyDefaults = require('./applyDefaults'),
    environmentProperties = require('./environmentProperties'),
    events = require('./events'),
    storage = require('./storage'),
    errorLogger = require('./logging'),
    analyticsEmitter = require('./analyticsEmitter'),
    telemetry = require('./telemetry'),

    // `render` should only be invoked once per page load;
    // use the following to log how often it has been called.
    renderCount = 0;

var THEMES = require('./component/themes');

telemetry.storeBrowserMetadata();
telemetry.checkpoint('loaded');

var bb = {
    render: render,
    updateDealerLabelHtml: updateDealerLabelHtml,
    updateUserData: updateUserData,
    collapseComponents: collapseComponents,
    onStateDetermined: onStateDetermined,
    onComponentExpanded: onComponentExpanded,
    version: require('../version.js'),
};

module.exports = bb;

function updateDealerLabelHtml(dealerLabelHtml) {
    updateActions.add(function() {
        bbc.setDealerLabelHtml(dealerLabelHtml);
    });
    return bb;
}

function updateUserData(keyHtml, valueHtml) {
    updateActions.add(function() {
        bbc.updateUserData({ keyHtml: keyHtml, valueHtml: valueHtml });
    });
    return bb;
}

function collapseComponents() {
    bbc.collapseComponents();
    return bb;
}

function normalizeEnv(config) {
    var orig = config.env,
        env = (orig || '').toLowerCase().trim();

    // must be a known env (anything else defaults to prod)
    config.env =
        ['dev', 'qa', 'local', 'dz_prod', 'dz_np'].indexOf(env) !== -1
            ? config.env
            : 'prod';

    // keep a copy of what the sg sent us, if different
    if (orig && orig !== config.env) {
        config.sgEnv = orig;
    }
}

function validateConfig(config) {
    var errors = [];

    if (!config.solutionGroupCode) {
        errors.push('config.solutionGroupCode is required');
    }

    if (!config.sessionId && config.useMockData !== true) {
        errors.push('config.sessionId is required');
    }

    if (config.getTracker && typeof config.getTracker !== 'function') {
        errors.push(
            'config.getTracker should be a function when it is provided'
        );
    }

    if (errors.length !== 0) {
        throw new Error(
            'Bridge Bar Configuration Error\n' +
                '=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~\n' +
                'Bridge bar render method was invoked with an invalid configuration and can not be properly initialized.\n' +
                'Please check your implementation of the bridge bar render call and verify that it provides all the required ' +
                'fields and that they are the correct type.\n\nThe following error(s) were found: \n * ' +
                errors.join('\n * ') +
                '\n=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~=~\n'
        );
    }
}
function init(config) {
    telemetry.checkpoint('render-called');

    // do this first, because we want the normalized env in the logs
    normalizeEnv(config);

    errorLogger.setLoggedParam('config', config);

    config = applyDefaults(config, {
        theme: THEMES[6].name,
        userLinks: [],
        userData: [],
    });

    validateConfig(config);

    telemetry.storeArtifact('config', config);
}
function render(config) {
    init(config);

    // log and no-op if this gets called more than once
    if (incrementAndLogRenderCount() > 1) {
        return bb;
    }

    analyticsEmitter.setTrackerProvider(config.getTracker);
    bbc.setAnalyticsEmitter(analyticsEmitter);

    var loadingTimeout;

    // Start timer for Minimal State

    bbc.show(config.theme, config.width);

    loadingTimeout = setTimeout(function() {
        telemetry.loadingShown();
        bbc.setUserMenu(
            bridgeApiAdapter.getLoadingState(config).userMenuObject
        );
    }, 1000);

    renderBB(config, loadingTimeout);
    return bb;
}

function renderBB(config, loadingTimeout) {
    // special token we throw to break out of "Promise.all" without logging an error
    var ABORT_PROMISES = '_abort_';

    Promise.all([
        getBridgeBarData(config).then(function(data) {
            // The bridge bar used to be able to be hidden depending on the api response. That is no longer supported, however we will still publish the "state determined" event as that is part of our spec.
            events.stateDetermined.publish(true, data.settings.error);
            return data;
        }),
        getOptions(config),
    ])
        .then(function(values) {
            var data = values[0],
                options = values[1],
                results = bridgeApiAdapter.applyOptions(data, options);

            clearTimeout(loadingTimeout);

            if (data && data.components) {
                var components = [];

                data.components.forEach(function(component) {
                    // Inject dynamic script into head tag
                    var el;

                    if (component.moduleSrc) {
                        el = document.createElement('script');
                        el.setAttribute('type', 'module');
                        el.setAttribute('src', component.moduleSrc);
                        document.head.appendChild(el);
                    }
                    if (component.noModuleSrc) {
                        el = document.createElement('script');
                        el.setAttribute('nomodule', '');
                        el.setAttribute('src', component.noModuleSrc);
                        document.head.appendChild(el);
                    }

                    // Build out instance of the element
                    if (component.tag) {
                        el = document.createElement(component.tag);
                        if (component.attributes) {
                            Object.keys(component.attributes).forEach(function(
                                attrKey
                            ) {
                                el.setAttribute(
                                    attrKey,
                                    component.attributes[attrKey]
                                );
                            });
                        }

                        if (config.language) {
                            el.setAttribute('bb_lang', config.language);
                        }

                        if (config.theme) {
                            el.setAttribute('bb_theme', config.theme);
                        }

                        if (config.env) {
                            el.setAttribute('bb_env', config.env);
                        }

                        if (config.sessionId) {
                            el.setAttribute('bb_session', config.sessionId);
                        }
                        components.push(el);
                    }
                });
                bbc.setWebComponents(components);
            }

            bbc.setLandingPageUrl(
                environmentProperties.get(config).landingPageUrl
            );
            bbc.setSolutionSwitcher(results.solutionSwitcherObject);
            bbc.setUserMenu(results.userMenuObject);

            if (options.dealerLabelHtml) {
                bbc.setDealerLabelHtml(options.dealerLabelHtml);
            }

            updateActions.trigger();

            // set up componentExpanded event emitter
            bbc.componentExpandedStream.subscribe(function() {
                events.componentExpanded.publish();
            });

            analyticsEmitter.emit({
                event: 'bbShown',
                eventData: {
                    platformId: results.platformId,
                    solutionSwitcherData: results.solutionSwitcherObject,
                    userMenuData: results.userMenuObject,
                    error: data.settings.error
                        ? data.settings.error.toString()
                        : null,
                },
            });

            telemetry.finalPaint(data.settings.error ? 'error' : 'shown');
        })
        .catch(function(err) {
            clearTimeout(loadingTimeout);

            telemetry.finalPaint(err !== ABORT_PROMISES ? 'error' : 'hidden');

            if (err !== ABORT_PROMISES) {
                errorLogger.error(err);
            }
        })
        .then(function() {
            telemetry.transmit();
        });
}

function incrementAndLogRenderCount() {
    renderCount = renderCount + 1;
    errorLogger.setLoggedParam('renderCount', renderCount);

    if (renderCount > 1) {
        // log once per browser session
        if (!storage.session.getItem('bridgeBar.renderLogged')) {
            storage.session.setItem('bridgeBar.renderLogged', true);
            errorLogger.warn(new Error('render() invoked more than once'));
        }
    }

    return renderCount;
}

function onStateDetermined(callback) {
    events.stateDetermined.subscribe(callback);
    return bb;
}

function onComponentExpanded(callback) {
    events.componentExpanded.subscribe(callback);
    return bb;
}

function getBridgeBarData(config) {
    telemetry.checkpoint('data-requested');
    var loadErr = null,
        serviceUrls,
        fetch;

    if (config.useMockData) {
        telemetry.setApiVersion('mocked');
        fetch = Promise.resolve(require('./bridgeApiMockData').bridgeApi);
    } else {
        var apiVersion = 'v1';

        serviceUrls = {
            bridgeBarApi: bridgeApiUrlBuilder.getBridgeBarApiUrl(config),
        };
        telemetry.registerUrls(serviceUrls);
        telemetry.setApiVersion(apiVersion);
        fetch = bridgeApiClient.fetchPayload({
            bridgeApiUrl: serviceUrls.bridgeBarApi,
            telemetryAppName: 'Bridge Bar Experiential API',
            accept: 'application/vnd.coxauto.' + apiVersion + '+json',
        });
    }

    return fetch
        .catch(function(err) {
            errorLogger.error(err);

            // pass error and null payload to next step to render error state
            loadErr = err;
            return null;
        })
        .then(function(payload) {
            telemetry.checkpoint('data-responded');
            return payload;
        })
        .then(function(payload) {
            var data = bridgeApiAdapter.convertFromApiPayload(
                payload,
                config,
                loadErr
            );

            keepAlive(data.settings.keepAliveUrl);

            return data;
        });
}

function getOptions(config) {
    return Promise.all([
        Promise.resolve(config.dealerLabelHtml),
        Promise.resolve(config.userLinks),
        Promise.resolve(config.userData),
    ]).then(function(values) {
        return {
            dealerLabelHtml: values[0],
            userLinks: values[1],
            userData: values[2],
        };
    });
}
