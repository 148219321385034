var element = document.createElement('p');

module.exports = {

    bridgeBar:
        (typeof element.style.flexBasis !== 'undefined' || typeof element.style['-ms-flex-pack'] !== 'undefined') &&
        'withCredentials' in new XMLHttpRequest() &&
        element.addEventListener &&
        element.querySelector &&
        element.classList &&
        Function.prototype.bind &&
        Array.isArray &&
        Array.prototype.forEach &&
        Array.prototype.filter &&
        Array.prototype.some &&
        Array.prototype.map &&
        window.JSON,

    customEvents:
        element.addEventListener &&
        element.dispatchEvent &&
        window.CustomEvent &&
        Array.prototype.forEach &&
        Array.prototype.indexOf
};

