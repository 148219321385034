var environmentProperties = require('./environmentProperties');

module.exports = {
    getLogOutUrl: getLogOutUrl,
    getBridgeBarKeepAliveUrl: getBridgeBarKeepAliveUrl,
    getBridgeBarApiUrl: getBridgeBarApiUrl
};

function getBridgeBarApiUrl(config) {
    var queryParams = {
        solutionId: config.solutionGroupCode,
        bridgeEnv: config.env,

        // Session id acts as a cache bust if the user logs out and logs back in
        sessionId: config.sessionId
    };

    if (config.env !== 'prod' && config.env !== 'dz_prod') {
        queryParams.partition = 'NON_PROD';
    }

    if (config.language) {
        queryParams.language = config.language;
    }

    return environmentProperties.get(config).bridgeBarApiUrl + getQueryString(queryParams);
}

function getBridgeBarKeepAliveUrl(config) {
    var queryParams = {};

    if (config.env !== 'prod' && config.env !== 'dz_prod') {
        queryParams.partition = 'NON_PROD';
    }

    return environmentProperties.get(config).keepAliveUrl + getQueryString(queryParams);
}

function getQueryString(queryParams) {
    if (Object.keys(queryParams).length === 0) {
        return '';
    }

    return '?' + Object.keys(queryParams)
        .map(function(k) { return encodeURIComponent(k) + '=' + encodeURIComponent(queryParams[k]); })
        .join('&');

}

function getLogOutUrl(config) {
    return environmentProperties.get(config).logoutUrl;
}
