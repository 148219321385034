/* eslint-disable camelcase */
var templatesMap = {
    local:{
        logoutUrl: 'https://signin.coxautoinc.com/logout?bridge_env=QA&bridge_solution={solution}',
        bridgeBarApiUrl: 'https://signin.dangerzone.coxautoservices.com/bridgebar/user-details/mine',
        keepAliveUrl: 'https://signin.dangerzone.coxautoservices.com/bridgebar/keep-alive',
        landingPageUrl: 'https://mycoxautomotive.dangerzone.coxautoservices.com/?partition=prod'
    },
    dz_np: {
        logoutUrl: 'https://signin.dangerzone.coxautoservices.com/logout?bridge_env=QA&bridge_solution={solution}',
        bridgeBarApiUrl: 'https://signin.dangerzone.coxautoservices.com/bridgebar/user-details/mine',
        keepAliveUrl: 'https://signin.dangerzone.coxautoservices.com/bridgebar/keep-alive',
        landingPageUrl: 'https://mycoxautomotive.dangerzone.coxautoservices.com/?partition=non-prod'
    },
    dz_prod: {
        logoutUrl: 'https://signin.dangerzone.coxautoservices.com/logout?&bridge_solution={solution}',
        bridgeBarApiUrl: 'https://signin.dangerzone.coxautoservices.com/bridgebar/user-details/mine',
        keepAliveUrl: 'https://signin.dangerzone.coxautoservices.com/bridgebar/keep-alive',
        landingPageUrl: 'https://mycoxautomotive.dangerzone.coxautoservices.com'
    },
    dev: {
        logoutUrl: 'https://signin.coxautoinc.com/logout?bridge_env=DEV&bridge_solution={solution}',
        bridgeBarApiUrl: 'https://signin.coxautoinc.com/bridgebar/user-details/mine',
        keepAliveUrl: 'https://signin.coxautoinc.com/bridgebar/keep-alive',
        landingPageUrl: 'https://mycoxautomotive.coxautoinc.com/?partition=non-prod'
    },
    qa: {
        logoutUrl: 'https://signin.coxautoinc.com/logout?bridge_env=QA&bridge_solution={solution}',
        bridgeBarApiUrl: 'https://signin.coxautoinc.com/bridgebar/user-details/mine',
        keepAliveUrl: 'https://signin.coxautoinc.com/bridgebar/keep-alive',
        landingPageUrl: 'https://mycoxautomotive.coxautoinc.com/?partition=non-prod'
    },
    prod: {
        logoutUrl: 'https://signin.coxautoinc.com/logout?bridge_solution={solution}',
        bridgeBarApiUrl: 'https://signin.coxautoinc.com/bridgebar/user-details/mine',
        keepAliveUrl: 'https://signin.coxautoinc.com/bridgebar/keep-alive',
        landingPageUrl: 'https://mycoxautomotive.coxautoinc.com'
    }
};

/* eslint-enable camelcase */

module.exports = {
    get: function(config) {
        var templates = templatesMap[config.env] || templatesMap.prod,
            settings = {};

        // The slash appended to the userCode below is a hack to avoid a double slash if the userCode is not provided.

        Object.keys(templates).forEach(function(key) {
            settings[key] = templates[key]
                .replace('{userCode}', config.userCode ? config.userCode + '/' : '')
                .replace('{solution}', config.solutionGroupCode)
                .replace('{now}', new Date().getTime());

        });

        return settings;
    }
};
