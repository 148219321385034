module.exports = {
    withMeta: withMeta
};

/* eslint-disable complexity */
function withMeta(err, response, data, moduleName) {
    var error,
        raw;

    if (err instanceof Error) {
        error = err;
    } else if (typeof err !== 'object') {
        error = new Error(err.toString());
    } else {
        error = new Error('Unidentified error');
        raw = JSON.stringify(err);
    }

    error.meta = {};

    if (raw) {
        error.meta.rawObject = raw;
    }

    if (response) {
        error.meta.type = response.type;
        error.meta.url = response.url;
        error.meta.status = response.status;
    }

    if (data) {
        error.meta.data = data;
    }

    if (moduleName) {
        error.message = error.message + ' in ' + moduleName;
    }

    return error;
}

