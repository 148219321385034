var bbEl = require('./bridgeBarElement'),
    browserSupports = require('./browserSupports'),
    stateDeterminedEvent = new BufferedSubscribableEvent(),
    componentExpandedEvent = new BufferedSubscribableEvent();

module.exports = {
    stateDetermined: {
        subscribe: function(callback) {
            stateDeterminedEvent.subscribe(callback);
        },
        publish: function(visible, error) {
            stateDeterminedEvent.publish({ visible: visible, error: error });

            if (browserSupports.customEvents) {
                // fire deprecated dom event
                bbEl.dispatchEvent(new CustomEvent('bridgeApiResponded', { detail: { hasBB: visible, error: error } }));
            }
        }
    },

    componentExpanded: {
        subscribe: function(callback) {
            componentExpandedEvent.subscribe(callback);
        },
        publish: function() {
            componentExpandedEvent.publish();

            if (browserSupports.customEvents) {
                // fire deprecated dom event
                bbEl.dispatchEvent(new CustomEvent('componentExpanded'));
            }
        }
    }
};

// copied from dt-events package
function BufferedSubscribableEvent() {
    var self = this,
        callbacks = [],
        buffer = [];

    self.subscribe = function(callback) {
        callbacks.push(callback);
        for (var i = 0; i < buffer.length; i++) {
            fireCallback(callback, buffer[i]);
        }
    };

    self.publish = function() {
        buffer.push(arguments);
        for (var i = 0; i < callbacks.length; i++) {
            fireCallback(callbacks[i], arguments);
        }
    };

    function fireCallback(callback, args) {
        try {
            callback.apply(null, args);
        } catch (err) {
            // eslint-disable-next-line no-console
            if (console && console.error) { console.error('Unhandled exception from callback caught in bridge bar\'s BufferedSubscribableEvent.', err); }
        }
    }
}
