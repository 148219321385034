var addChildElement = require('./addChildElement'),
    createElement = require('./createElement'),
    eventHelper = require('./eventHelper'),
    popoverHandler,
    analyticsEmitter;

module.exports = function(ph, ae) {
    var ssWrapper = createElement('div', 'bridge-bar-solution-switcher');

    popoverHandler = ph;
    analyticsEmitter = ae;

    return {
        attach: function(bbEl) {
            bbEl.appendChild(ssWrapper);
        },

        build: function(ss) {

            // If there are neither solution groups nor solution links, do NOT render the solution switcher
            if (noGroups(ss) && noLinks(ss)) {
                return;
            }

            if (ssWrapper.querySelector('.bb-solution-switcher')) {
                ssWrapper.innerHTML = '';
            }

            var trigger = addChildElement(ssWrapper, 'div', 'bb-solution-switcher'),
                popover = addChildElement(ssWrapper, 'div', 'bb-popover'),
                head = addChildElement(popover, 'div', 'bb-popover__head'),
                solutionName = addChildElement(trigger, 'p');

            solutionName.id = 'bb-solution-name';

            if (ss.currentId) {
                var found = ss.solutionGroups.filter(function(link) {
                    return isMatchingSolution(link.id, ss.currentId);
                });

                if (found.length === 0) {
                    // BB loaded from a non-solution app
                    solutionName.innerHTML = '';
                } else {
                    solutionName.innerHTML = found[0].label;
                }
            }

            if (ss.labelHtml !== null) {
                head.innerHTML = ss.labelHtml;
            }

            // If there are solution groups, display them
            if (!noGroups(ss)) {
                var body = addChildElement(popover, 'div', 'bb-popover__body');

                addSolutionLinks(body, ss.solutionGroups, ss.currentId, 'bb-solution-groups');
            }

            // If there are solution links, display them
            if (!noLinks(ss)) {
                var tail = addChildElement(popover, 'div', 'bb-popover__tail');

                addSolutionLinks(tail, ss.solutionLinks, ss.currentId, 'bb-solution-links');
            }

            popoverHandler.attach('solutionSwitcher', 'solution switcher', trigger);
        },
    };
};

function noGroups(ss) {
    return !ss.solutionGroups ||
        !ss.solutionGroups.length;
}

function noLinks(ss) {
    return !ss.solutionLinks ||
        !ss.solutionLinks.length;
}

function addSolutionLinks(parent, links, currentId, parentClassName) {
    var ul = addChildElement(parent, 'ul', parentClassName);

    if (parentClassName === 'bb-solution-groups') {
        links.forEach(function(link) {
            addSolutionLink(ul, link, currentId, 'bb-solution-group', 'solution group link');
        });
    }

    if (parentClassName === 'bb-solution-links') {
        links.forEach(function(link) {
            addSolutionLink(ul, link, currentId, 'bb-solution-link', 'solution user link');
        });
    }
}

function addSolutionLink(parent, link, currentId, childClassName, eventElement) {
    var li = addChildElement(parent, 'li', childClassName);

    if (isMatchingSolution(link.id, currentId)) {
        li.classList.add('current');
    }

    if (link.className) {
        li.classList.add(link.className);
    }
    var a = addChildElement(li, 'a', null, link.label);

    a.id = link.id || '';
    a.href = link.url || '';
    a.target = link.target || '_top';

    a.addEventListener('click', function(e) {
        emitEvent(link.id, 'click', eventElement, e);
    });

    a.addEventListener('contextmenu', function() {
        emitEvent(link.id, 'contextmenu', eventElement);
    });
}

function emitEvent(linkId, eventName, eventElement, eventData) {
    analyticsEmitter.emit({
        event: 'userEvent',
        eventFeature: 'bridge bar',
        eventFeatures: 'bridge bar > solution switcher > popover',
        eventElement: eventElement,
        eventAction: eventHelper.determineAction(eventName, eventData),
        eventResult: 'navigated to ' + linkId
    });
}

function isMatchingSolution(solutionId, compareSolutionId) {
    var matchingSolution = solutionId && compareSolutionId && solutionId.toLowerCase().indexOf(compareSolutionId.toLowerCase()) === 0;

    return matchingSolution;
}
