var withMeta = require('./errorHelper').withMeta,
    applyDefaults = require('./applyDefaults'),
    telemetry = require('./telemetry');

module.exports = {

    // options:
    // - bridgeApiUrl
    // - locale (optional)

    fetchPayload: function(options) {
        options = applyDefaults(options, {
            locale: 'en-us',
            accept: 'application/json'
        });

        var startTime = new Date().getTime();

        return Promise.resolve()
            .then(function() {
                if (!options.bridgeApiUrl) {
                    var e = new Error('No Bridge API url.');

                    e.meta = {
                        code: 'bridge-api-null'
                    };
                    throw e;
                }

                // application/json media type below tells server to disable http browser caching
                return fetch(encodeURI(options.bridgeApiUrl), {
                    credentials: 'include',
                    mode: 'cors',
                    method: 'GET',
                    headers: {
                        'Accept-Language': options.locale,
                        'Accept': options.accept
                    }
                })
                .then(function(response) {

                    telemetry.setApiAttempt(options.telemetryAppName, response.status, (new Date().getTime() - startTime));

                    return response.text()
                        .then(function(data) {
                            if (response.status >= 400) {
                                throw withMeta(new Error(options.telemetryAppName + ' returned ' + response.status + '.'), response, data, 'bridgeApiClient');
                            }

                            try {
                                return JSON.parse(data);
                            } catch (err) {
                                throw withMeta(new Error('Response is not JSON from ' + options.telemetryAppName), response, data, 'bridgeApiClient');
                            }
                        },
                        function(err) {
                            throw withMeta(err, response, null, 'bridgeApiClient');
                        });
                },
                function(err) {
                    var error;
                    var response;

                    if (looksLikeAResponse(err)) {
                        telemetry.setApiAttempt(options.telemetryAppName, response.status, (new Date().getTime() - startTime));
                        error = new Error('Request failed.');
                        response = err;
                    }
                    else {
                        error = err;
                        response = {
                            url : options.bridgeApiUrl,
                            status : -1,
                            type : 'cors'
                        };
                    }
                    throw withMeta(error, response, null, 'bridgeApiClient');
                });
            });
    }
};

function looksLikeAResponse(response) {
    return Boolean(response.url) && Boolean(response.status);
}
