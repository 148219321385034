var createElement = require('./createElement');

module.exports = function() {
    var componentWrapper = createElement('div', 'bridge-bar-web-component-wrapper');

    return {
        attach: function(bbEl) {
            bbEl.appendChild(componentWrapper);
        },

        addComponent: function(component) {
            componentWrapper.appendChild(component);
        }
    };
};
