var createElement = require('./createElement'),
    analyticsEmitter,
    eventHelper = require('./eventHelper');

module.exports = function(ae) {

    analyticsEmitter = ae;
    var logoWrapper = createElement('div', 'bridge-bar-company-logo');
    var landingPageUrl;

    function goToLandingPage() {
        window.location.href = landingPageUrl;
    }

    return {
        attach: function(bbEl) {
            logoWrapper.classList.add('bb-logo-hidden');
            logoWrapper.addEventListener('click', function(e) {
                emitEvent(landingPageUrl, 'click', e);
            });
            logoWrapper.onclick = goToLandingPage;
            bbEl.appendChild(logoWrapper);
        },

        show: function() {
            logoWrapper.classList.remove('bb-logo-hidden');

        },

        hide: function() {
            logoWrapper.classList.add('bb-logo-hidden');
        },

        setLandingPageUrl: function(url) {
            logoWrapper.setAttribute('data-url', url);
            landingPageUrl = url;
        }
    };
};

function emitEvent(url, eventName, eventData) {
    analyticsEmitter.emit({
        event: 'userEvent',
        eventFeature: 'bridge bar',
        eventFeatures: 'bridge bar > bridge bar company logo',
        eventElement: 'bridge-bar-company-logo',
        eventAction: eventHelper.determineAction(eventName, eventData),
        eventResult:  'navigated to ' + url
    });
}
