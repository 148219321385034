module.exports = function(target, defaults) {
    if (!target) {
        target = {};
    }
    for (var k in defaults) {
        if (defaults.hasOwnProperty(k) && !target.hasOwnProperty(k)) {
            target[k] = defaults[k];
        }
    }
    return target;
};
